import { LoginLayout } from '@/components/layouts/LoginLayout';
import { LoginPlatform } from '@/components/modules/LoginPlatform';
import React from 'react';

function index() {
  return (
    <LoginLayout>
      <LoginPlatform />
    </LoginLayout>
  );
}

export default index;
